import React, { Component, createRef, RefObject } from 'react';
import ReactDOM from 'react-dom';
import {
    Icon,
    FocusTrapZone,
} from '@fluentui/react';
import styles from './ProactiveChatModal.module.scss';
import generalStyles from 'styles/generalStyles.module.scss';

interface IProactiveChatModal {
    cancelOnClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) => void;
    chatNowOnClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null) => void;
    chatMarketImage: string;
    needHelp: string;
    sectionText: string;
    chatModalAria: string;
    chatNow: string;
    chatNowAria: string;
    noThanks: string;
    noThanksAria: string;
    modalCloseButton: string;
    isRTLCulture: boolean;
}

export default class ProactiveChatModalComponent extends Component<IProactiveChatModal, {}> {
    private el: any;
    private modalRoot: any;
    private node: RefObject<HTMLDivElement>;

    constructor(props: IProactiveChatModal) {
        super(props);
        this.el = document.createElement('div');
        this.modalRoot = document.getElementById('modal-root');
        this.node = createRef();
    }

    componentDidMount() {
        if (this.modalRoot) {
            this.modalRoot.appendChild(this.el);
        }
        if (this.node && this.node.current) {
            this.node.current.focus({
                preventScroll: true,
            });
        }
        // Fire the telemetry event explicitly
        this.logModalAppearanceTelemetry();
    }
    
    logModalAppearanceTelemetry() {
        // Find the modal wrapper element dynamically
        const modalElement = document.querySelector(`.${styles.modalWrapper}`);
        if (!modalElement) {
            console.warn('Modal element not found in the DOM.');
            return;
        }
    
        // Dynamically fetch the telemetry data attributes
        const telemetryPayload = {
            behavior: modalElement.getAttribute('data-bi-bhvr'), // Fetches `data-bi-bhvr`
            actionName: modalElement.getAttribute('data-bi-an'), // Fetches `data-bi-an`
            containerName: modalElement.getAttribute('data-bi-cn'), // Fetches `data-bi-cn`
        };
    
        // Send the telemetry event
        if (window.analytics) {
            window.analytics.track('ModalAppeared', telemetryPayload);
        } else {
            console.warn('Analytics library is not available.');
            console.log('Telemetry Payload:', telemetryPayload); // Debugging
        }
    }    
    
    componentWillUnmount() {
        if (this.modalRoot) {
            this.modalRoot.removeChild(this.el);
        }
    }    

    keydown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            event.key === 'Escape' ||
            event.key === 'ArrowLeft' ||
            event.key === 'ArrowRight'
        ) {
            this.props.cancelOnClick(null);
        }
    };

    renderContainer = () => {
        const {
            chatMarketImage,
            chatNowOnClick,
            cancelOnClick,
            needHelp,
            sectionText,
            chatModalAria,
            chatNow,
            chatNowAria,
            noThanks,
            noThanksAria,
            modalCloseButton,
            isRTLCulture
        } = this.props;

        return (
            <FocusTrapZone>
                <div
                    className={`${styles.modalWrapper}`}
                    data-bi-bhvr="14"
                    data-bi-an="ProactiveChat_Modal" // Action name for modal
                    data-bi-cn="ChatInvite" // Container name
                >
                    <div className={`${styles.modalBorder}`}
                    data-bi-bhvr="14">
                    <div
                        id="lp-proactive-chat"
                        data-bi-bhvr="14"
                        className={`${styles.lp_proactive_chat} ${styles.content}`}
                        tabIndex={0}
                        onKeyDown={this.keydown}
                        aria-live="polite"
                        aria-label={chatModalAria}
                        ref={this.node}
                    >

                        <div>
                            <button
                                className={`${styles.modalClose}`}
                                aria-label={modalCloseButton}
                                onClick={() => cancelOnClick(null)}
                                data-bi-bhvr="15"
                                id="chatDisengagement"
                                data-bi-cn="Chat_invite_decline"
                            >
                                X
                            </button>
                        </div>

                        <div className={`column ${styles.lp_proactive_chat_body}`}>
                            <div className="row">
                                <h3 className={`${styles.text_heading3}`}>{needHelp}</h3>
                                <p>{sectionText}</p>
                            </div>
                        </div>

                        <div className={`${styles.lp_proactive_chat_buttons}`}>
                            <button
                                id="chatEngagement"
                                data-bi-bhvr="180"
                                data-bi-cn="Chat_invite_accept"
                                className={`${styles.chatEngagement}`}
                                onClick={chatNowOnClick}
                                aria-label={chatNowAria}
                            >
                                <span>
                                    {chatNow}
                                    <Icon
                                        iconName={isRTLCulture ? 'ChevronLeftSmall' : 'ChevronRightSmall'}
                                        className={`${generalStyles.ChevronStyle}`}
                                    />
                                </span>
                            </button>

                            <button
                                id="chatDisengagement"
                                data-bi-bhvr="15"
                                data-bi-cn="Chat_invite_decline"
                                className={`${styles.lp_chatnow_cancel_button}`}
                                onClick={cancelOnClick}
                                aria-label={noThanksAria}
                            >
                                <span className={`${styles.cancelSpan}`}>
                                    {noThanks}
                                    <Icon
                                        iconName={isRTLCulture ? 'ChevronLeftSmall' : 'ChevronRightSmall'}
                                        className={`${generalStyles.ChevronStyle}`}
                                    />
                                </span>
                            </button>

                        </div>

                        </div>
                        </div>
                    </div>
            </FocusTrapZone>
        );
    };

    render() {
        return ReactDOM.createPortal(this.renderContainer(), this.el);
    }
}
